import React from "react";
import axios from "axios";
import {
  B2B_PORTAL_JWT_SECRET_KEY,
  B2B_PORTAL_CSRF_TOKEN,
  B2B_PORTAL_AUTH_REDIRECT_TO,
  B2B_PORTAL_UNAUTHORIZED_PATH,
  NETWORK_CALL_FAILED,
} from "redmond";
import { useHistory, useLocation } from "react-router-dom";
import { analyticsEventApi } from "../../api/v1/paths";
import { getUserTrackingProperties } from "halifax";
import { trackEvent } from "../../api/v1/analytics/trackEvent";
import { config } from "../../api/config";
interface IAxiosInterceptors {
  children?: React.ReactNode;
  isAgentPortal: boolean;
}

const defaultProps: Partial<IAxiosInterceptors> = {
  isAgentPortal: false,
};

const AxiosInterceptors = (props: IAxiosInterceptors) => {
  const { children, isAgentPortal } = props;
  const history = useHistory();
  const location = useLocation();

  const getCookie = (name: string) => {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    if (match) return match[2];
    else return "";
  };

  const getCsrfToken = () => getCookie(B2B_PORTAL_CSRF_TOKEN);

  const setAuthRedirectUrl = (url: string) =>
    sessionStorage.setItem(B2B_PORTAL_AUTH_REDIRECT_TO, url);

  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response?.status === 401) {
        setAuthRedirectUrl(location.pathname);
        // TODO:Change to not found page to indicate agents have a bad profileReferenceId
        history.push(B2B_PORTAL_UNAUTHORIZED_PATH);
        return Promise.reject(error);
      }
      if (
        error?.request?.responseURL &&
        !error?.request?.responseURL.includes("event") &&
        !error?.request?.responseURL.includes("userInfo")
      ) {
        trackEvent({
          eventName: NETWORK_CALL_FAILED,
          properties: {
            url: window.location.pathname,
            endpoint: error?.request?.responseURL,
            failure_code: error?.request?.status,
            failure_reason: error?.request?.statusText,
            failure_message: error?.request?.responseText,
          },
        });
      }
      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use(
    (req) => {
      const csrfToken = getCsrfToken();
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          B2B_PORTAL_JWT_SECRET_KEY
        )}`,
        ...(csrfToken && { "H-Csrf-Token": csrfToken }),
      };

      req.headers.set({ ...req.headers, ...headers });

      if (req.url === analyticsEventApi) {
        req.data.properties = {
          ...req.data.properties,
          ...getUserTrackingProperties(config.TENANT),
          is_agent_session: isAgentPortal,
        };
      }

      return req;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return <>{children}</>;
};

AxiosInterceptors.defaultProps = defaultProps;

export default AxiosInterceptors;
